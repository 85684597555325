import '../App.css';
import React, {useLayoutEffect} from 'react';
import './books.css';
import {Container , Row, Col} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import HTMLFlipBook from 'react-pageflip';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderUnderlineAnimation from '../HeaderUnderlineAnimation/HeaderUnderlineAnimation';
import HomeContactSection from '../HomeContactSection';
// import Logo from '../images/webp/dsi-logo.webp';
import ButtonAnimation from '../ButtonAnimation/ButtonAnimation';
import ISBNIcon from '../images/webp/barcode-icon.png';
import CalendarIcon from '../images/webp/calendar-icon.png';
// import LanguageIcon from '../images/webp/language-icon.png';
import AuthorIcon from '../images/webp/author-icon.png';
import PublisherIcon from '../images/webp/publisher-icon1.png';
import PagesIcon from '../images/webp/pages-icon.png';
// import SizeIcon from '../images/webp/size-icon.png';
import Book_iso from '../images/webp/book-iso.png';
import Page1 from '../images/book_sample_pages/1.jpg';
import Page2 from '../images/book_sample_pages/2.jpg';
import Page2a from '../images/book_sample_pages/2a.jpg';
import Page3 from '../images/book_sample_pages/3.jpg';
import Page4 from '../images/book_sample_pages/4.jpg';
import Page5 from '../images/book_sample_pages/5.jpg';
import Page6 from '../images/book_sample_pages/6.jpg';
import Page7 from '../images/book_sample_pages/7.jpg';
import Page8 from '../images/book_sample_pages/8.jpg';
import Page9 from '../images/book_sample_pages/9.jpg';
import Page10 from '../images/book_sample_pages/10.jpg';
import Page11 from '../images/book_sample_pages/11.jpg';
import Page12 from '../images/book_sample_pages/12.jpg';
import Page12a from '../images/book_sample_pages/12a.jpg';
import Page13 from '../images/book_sample_pages/13.jpg';
import Page14 from '../images/book_sample_pages/14.jpg';
// import Page15 from '../images/book_sample_pages/15.jpg';
import Page16 from '../images/book_sample_pages/16.jpg';
import Page17 from '../images/book_sample_pages/17.jpg';


// import P1 from '../images/book-flip-video-images/1.jpg';
// import P2 from '../images/book-flip-video-images/2.jpg';
// import P3 from '../images/book-flip-video-images/3.jpg';
// import P4 from '../images/book-flip-video-images/4.jpg';
// import P5 from '../images/book-flip-video-images/5.jpg';
// import P6 from '../images/book-flip-video-images/6.jpg';
// import P7 from '../images/book-flip-video-images/7.jpg';
// import P8 from '../images/book-flip-video-images/8.jpg';
// import P9 from '../images/book-flip-video-images/9.jpg';
// import P10 from '../images/book-flip-video-images/10.jpg';
// import P11 from '../images/book-flip-video-images/11.jpg';
// import P12 from '../images/book-flip-video-images/12.jpg';
// import P13 from '../images/book-flip-video-images/13.jpg';
// import P14 from '../images/book-flip-video-images/14.jpg';
// import P15 from '../images/book-flip-video-images/15.jpg';
// import P16 from '../images/book-flip-video-images/16.jpg';
// import P17 from '../images/book-flip-video-images/17.jpg';
// import P18 from '../images/book-flip-video-images/18.jpg';
// import P19 from '../images/book-flip-video-images/19.jpg';
// import P20 from '../images/book-flip-video-images/20.jpg';
// import P21 from '../images/book-flip-video-images/21.jpg';
// import P22 from '../images/book-flip-video-images/22.jpg';
// import P23 from '../images/book-flip-video-images/23.jpg';
// import P24 from '../images/book-flip-video-images/24.jpg';
// import P25 from '../images/book-flip-video-images/25.jpg';
// import P26 from '../images/book-flip-video-images/26.jpg';
// import P27 from '../images/book-flip-video-images/27.jpg';
// import P28 from '../images/book-flip-video-images/28.jpg';
// import P29 from '../images/book-flip-video-images/29.jpg';
// import P30 from '../images/book-flip-video-images/30.jpg';
// import P31 from '../images/book-flip-video-images/31.jpg';
// import P32 from '../images/book-flip-video-images/32.jpg';
// import P33 from '../images/book-flip-video-images/33.jpg';
// import P34 from '../images/book-flip-video-images/34.jpg';
// import blankPage from '../images/book-flip-video-images/blank.jpg';



function Details(props){
    return(
        <>
        <Col xs={12} lg={2} className='details-component'>
            <p className='details-header'> {props.title}</p>
            <img className='details-icon fit-image' src={props.icon} alt= {props.alt}/> 
            <p style={{fontWeight: 'bold'}}> {props.description} </p>
        </Col>
        </>
    )
}

function MyBook(props) {
    return (
      <HTMLFlipBook className='flipcontainer' width={420} height={596}>
        <div className="demoPage"><img className='fit-image' src={Page1} alt='page1'/></div>
        <div className="demoPage"><img className='fit-image' src={Page2} alt='page2'/></div>
        <div className="demoPage"><img className='fit-image' src={Page2a} alt='page2a'/></div>
        <div className="demoPage"><img className='fit-image' src={Page3} alt='page3'/></div>
        <div className="demoPage"><img className='fit-image' src={Page4} alt='page4'/></div>
        <div className="demoPage"><img className='fit-image' src={Page5} alt='page5'/></div>
        <div className="demoPage"><img className='fit-image' src={Page6} alt='page6'/></div>
        <div className="demoPage"><img className='fit-image' src={Page7} alt='page7'/></div>
        <div className="demoPage"><img className='fit-image' src={Page8} alt='page8'/></div>
        <div className="demoPage"><img className='fit-image' src={Page9} alt='page9'/></div>
        <div className="demoPage"><img className='fit-image' src={Page10} alt='page10'/></div>
        <div className="demoPage"><img className='fit-image' src={Page11} alt='page11'/></div>
        <div className="demoPage"><img className='fit-image' src={Page12} alt='page12'/></div>
        <div className="demoPage"><img className='fit-image' src={Page12a} alt='page12a'/></div>
        <div className="demoPage"><img className='fit-image' src={Page13} alt='page13'/></div>
        <div className="demoPage"><img className='fit-image' src={Page14} alt='page14'/></div>
        {/* <div className="demoPage"><img className='fit-image' src={Page15} alt='page15'/></div> */}
        <div className="demoPage"><img className='fit-image' src={Page16} alt='page16'/></div>
        {/* <div className="demoPage"><img className='fit-image' src={Page17} alt='page17'/></div> */}
      </HTMLFlipBook>
    );
  }

//   function VideoBook(props) {
//     return (
//       <HTMLFlipBook className='flipcontainer' width={420} height={596}>
//         <div className="demoPage"><img className='fit-image' src={blankPage} alt='page1'/></div>
//         <div className="demoPage"><img className='fit-image' src={P1} alt='page1'/></div>
//         <div className="demoPage"><img className='fit-image' src={P2} alt='page2'/></div>
//         <div className="demoPage"><img className='fit-image' src={P3} alt='page2a'/></div>
//         <div className="demoPage"><img className='fit-image' src={P4} alt='page3'/></div>
//         <div className="demoPage"><img className='fit-image' src={P5} alt='page4'/></div>
//         <div className="demoPage"><img className='fit-image' src={P6} alt='page5'/></div>
//         <div className="demoPage"><img className='fit-image' src={P7} alt='page6'/></div>
//         <div className="demoPage"><img className='fit-image' src={P8} alt='page7'/></div>
//         <div className="demoPage"><img className='fit-image' src={P9} alt='page8'/></div>
//         <div className="demoPage"><img className='fit-image' src={P10} alt='page9'/></div>
//         <div className="demoPage"><img className='fit-image' src={P11} alt='page10'/></div>
//         <div className="demoPage"><img className='fit-image' src={P12} alt='page11'/></div>
//         <div className="demoPage"><img className='fit-image' src={P13} alt='page12'/></div>
//         <div className="demoPage"><img className='fit-image' src={P14} alt='page12a'/></div>
//         <div className="demoPage"><img className='fit-image' src={P15} alt='page13'/></div>
//         <div className="demoPage"><img className='fit-image' src={P16} alt='page14'/></div>
//         <div className="demoPage"><img className='fit-image' src={P17} alt='page1'/></div>
//         <div className="demoPage"><img className='fit-image' src={P18} alt='page2'/></div>
//         <div className="demoPage"><img className='fit-image' src={P19} alt='page2a'/></div>
//         <div className="demoPage"><img className='fit-image' src={P20} alt='page3'/></div>
//         <div className="demoPage"><img className='fit-image' src={P21} alt='page4'/></div>
//         <div className="demoPage"><img className='fit-image' src={P22} alt='page5'/></div>
//         <div className="demoPage"><img className='fit-image' src={P23} alt='page6'/></div>
//         <div className="demoPage"><img className='fit-image' src={P24} alt='page7'/></div>
//         <div className="demoPage"><img className='fit-image' src={P25} alt='page8'/></div>
//         <div className="demoPage"><img className='fit-image' src={P26} alt='page9'/></div>
//         <div className="demoPage"><img className='fit-image' src={P27} alt='page10'/></div>
//         <div className="demoPage"><img className='fit-image' src={P28} alt='page11'/></div>
//         <div className="demoPage"><img className='fit-image' src={P29} alt='page12'/></div>
//         <div className="demoPage"><img className='fit-image' src={P30} alt='page12a'/></div>
//         <div className="demoPage"><img className='fit-image' src={P31} alt='page13'/></div>
//         <div className="demoPage"><img className='fit-image' src={P32} alt='page14'/></div>
//         <div className="demoPage"><img className='fit-image' src={P33} alt='page12a'/></div>
//         <div className="demoPage"><img className='fit-image' src={P34} alt='page13'/></div>
//         {/* <div className="demoPage"><img className='fit-image' src={P35} alt='page14'/></div> */}
//       </HTMLFlipBook>
//     );
//   }

function HeroSection() {
    return(
        <>
            <header className="hero-header book-header">
        
                <img src={Book_iso} alt='book isometric' className='book-image'/>
                <div className="overlay courses-header-text">
                <h1 className='text-uppercase'> Drone Technology for Beginners</h1>
                <h4>Learn | Build | Fly Drones</h4>
                <a href="https://amzn.in/d/0EauLBB" className='text-decoration-none'><ButtonAnimation text='Buy Now' color='black'/></a>
                <NavLink to="/contact" className='text-decoration-none'><ButtonAnimation text='get in touch' color='black'/></NavLink>
                {/* <br/> */}
                </div>
            </header>
        </>
    )
}


function Books() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <>
            <div className='about-component'>
                <HeroSection />
            
            <Container className='about-conatiner'>
                <div className='about-dsi-div'>
                <h2><HeaderUnderlineAnimation text="Sample Pages Preview" lineColor="orange"> </HeaderUnderlineAnimation></h2>
                    <br/>
                    <Row>
                        <Col xs={12} lg={12} className='about-dsi-logo-col'>
                            <MyBook />
                        </Col>
                        {/* <h2><HeaderUnderlineAnimation text="Sample Pages Preview" lineColor="orange"> </HeaderUnderlineAnimation></h2>
                    <br/>
                        <Col xs={12} lg={12} className='about-dsi-logo-col'>
                            <VideoBook />
                        </Col> */}
                        <Col xs={12} lg={12}>
                            <div className='about-heading-overlay'>
                                <h2 className='about-heading'><HeaderUnderlineAnimation text="About the Book" lineColor="orange"> </HeaderUnderlineAnimation></h2>
                            </div>
                        
                            <div>
                            <p className='text-justify'>
                                This book “Drone Technology for Beginners: Learn | Build | Fly Drones”, is an initiative by Drone School India. With this book, we aimed to develop a curated and structured curriculum for students learning aerial robotics. 
                                <br /><br />
                                Throughout this book, the readers will explore the intricacies of drone technology, understanding their inner workings, movements and controls, learn about the different types and their unique features, study the aerodynamics behind their movements, and explore their applications in diverse fields. After a detailed explanation of drone fundamentals,  the reader delves into the Pluto drone kit to understand the Pluto drones ecosystem and its potential. They will learn to build their very own Pluto nano drone, and take it on its maiden flight.  The book encourages indulging in various flying activities to master drone maneuvering skills.
                                <br /><br />
                                The book educates readers on crucial safety measures associated with drones, covering rules, regulations, and best practices. It emphasizes do’s and don’ts to ensure safe and responsible drone usage. 
                                <br /><br />
                                The book incorporates several illustrations, diagrams and screenshots enhances the book’s visual appeal and facilitates a clearer understanding, providing readers with visual aids to complement and reinforce the textual explanations. Worksheets for practice are included in each chapter.
                            </p>
                            </div>

                            <div>

                            </div>
                        </Col>
                    </Row>
                    </div>
                    <Row className='verticals-row'>
                        <Details icon={AuthorIcon} alt='Author Icon' title='Authors' description='Dharna Nar and Radhika Kotecha'/>
                        <Details icon={PublisherIcon} alt='Inspire1 Gif' title='Publisher' description='Drone School India and Ane Books Pvt. Ltd.'/>
                        <Details icon={PagesIcon} alt='Pages Icon' title='Print Length' description = '188 Pages'/>
                        <Details icon={CalendarIcon} alt='Calendar Icon' title='Publication Date' description='9 April 2024'/>
                        <Details icon={ISBNIcon} alt='Book ISBN Icon' title='ISBN-13' description='Paperback: 978-81-972221-8-4'/>
                        <Details icon={ISBNIcon} alt='Book ISBN Icon' title='ISBN-13' description = 'Hardback: 978-81-972221-5-3'/>
                    {/* <Details icon={SizeIcon} alt='Dimensions Icon' title='Size' description = 'A5'/> */}
                    {/* <Details icon={LanguageIcon} alt='Language Icon' title='Language' description = 'English'/> */}
                    </Row>

                    <div className='about-dsi-div'>
                    <Row>
                        <Col xs={12} lg={7}>
                            <div className='about-heading-overlay'>
                            <h2><HeaderUnderlineAnimation text="Salient Features" lineColor="orange"> </HeaderUnderlineAnimation></h2>
                            </div>
                        
                            <div>
                            <p className='text-justify'>
                            1. First comprehensive guide dedicated to Pluto drones, proudly made in India.
                            <br /><br />
                            2. Solid theoretical foundation on drone technology. <br /><br />
                            3. Step-by-step guidance on building drone. <br /><br />
                            4. Activities included for mastering drone flying. <br /><br />
                            5. Includes regulations and safety essentials.<br /><br />
                            6. Over 250 illustrations, diagrams, and screenshots for enhanced visual understanding. <br /><br />
                            7. Worksheets included for practice.
                            </p>
                            </div>

                            <div>

                            </div>
                        </Col>
                        <Col xs={12} lg={5} className='about-dsi-logo-col'>
                            <img className='fit-image salient-drone-image' src={Book_iso} alt='Book Isometric' />
                        </Col>
                    </Row>
                    </div>             
                    
                </Container>
                <HomeContactSection />

            </div>
        </>
    )}

export default Books;